import React from "react";
import backgroundVideo from "./assets/video/backgroundVideo.mp4";
import Logo from "./assets/svg/fcd-logo.svg";

function App() {
  return (
    <div className="App" id="App">
      <video autoPlay muted loop id="backgroundVideo">
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      <div id="backgroundMask" />
      <p className="brand">First Coding</p>

      <img src={Logo} className="rotating" alt="FCD Logo" id="logo" />

      <p className="brand">Division</p>
    </div>
  );
}

export default App;
